<!-- src/views/PrivacyPolicyView.vue -->
<template>
  <div class="privacy-container">
    <h1 class="privacy-title">Privacy Policy</h1>
    <p class="privacy-text">
      At Henium LLC, we prioritize your privacy and are committed to safeguarding your personal information. We do not collect, store, or process any identifying information from our website visitors. Our platform is designed to ensure that your interactions remain anonymous and secure.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PrivacyPolicyView',
});
</script>

<style scoped>
.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.6;
  color: #444;
}

.privacy-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.privacy-text {
  font-size: 1.2rem;
  text-align: justify;
}
</style>