import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "nav-links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "logo-link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.logo,
              alt: "Henium",
              class: "logo-image"
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("nav", _hoisted_5, [
        _createVNode(_component_router_link, { to: "/privacy" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Privacy Policy")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/contact" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Contact")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}