<!-- src/views/ContactView.vue -->
<template>
  <div class="contact-container">
    <h1 class="contact-title">Contact Info</h1>
    <p class="contact-text">
      If you have any question, please email general@henium.com
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContactView',
});
</script>

<style scoped>
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  line-height: 1.6;
  color: #444;
}

.contact-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-text {
  font-size: 1.2rem;
  text-align: justify;
}
</style>