// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/HomeView.vue';
import Privacy from '../views/PrivacyPolicyView.vue';
import Contact from '../views/ContactView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: Home,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicyView',
    component: Privacy,
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: Contact, 
  },
  // Redirect unknown routes to Home
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
