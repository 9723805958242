<!-- src/views/HomeView.vue -->
<template>
  <div class="home-container">
    <h1 class="company-title">Henium LLC</h1>
    <p class="company-description">Simplify life with mobile apps.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
});
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust as needed */
  text-align: center;
  padding: 20px;
}

.company-title {
  font-size: 3rem;
  margin-bottom: 20px;
}

.coming-soon {
  font-size: 2rem;
  color: #555;
}

.company-description {
  font-size: 2rem;
  color: #555;
}
</style>
