<!-- src/App.vue -->
<template>
  <div id="app">
    <header class="header">
      <div class="logo">
        <!-- Optional: Add a logo or brand name -->
        <router-link to="/" class="logo-link">
          <img :src="logo" alt="Henium" class="logo-image" />
        </router-link>
      </div>
      <nav class="nav-links">
        <router-link to="/privacy">Privacy Policy</router-link>
        <router-link to="/contact">Contact</router-link>
      </nav>
    </header>
    
    <main>
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import logo from '@/assets/white_logo.svg'; // Importing the logo

export default defineComponent({
  name: 'HeaderView',
  data() {
    return {
      logo, // Making the logo available in the template
    };
  },
});
</script>

<style>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.footer a {
  color: #000; /* Black color */
  font-size: 1rem;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.header {
  display: flex; /* Activates Flexbox */
  justify-content: space-between; /* Spaces out logo and nav */
  align-items: center; /* Vertically centers items */
  padding: 20px 40px; /* Adds padding */
  background-color: #282c34; /* Dark background color */
  color: white; /* White text color */
}

.logo-link {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.logo-link:hover {
  color: #61dafb; /* Light blue on hover */
}

.nav-links {
  display: flex; /* Flex container for navigation links */
  gap: 20px; /* Even spacing between links */
}

.nav-links a {
  color: white; /* White link color */
  text-decoration: none; /* Removes underline */
  font-size: 1em;
  transition: color 0.3s ease; /* Smooth color transition */
}

.nav-links a:hover {
  color: #61dafb; /* Light blue on hover */
}

.logo-image {
  height: 40px; /* Adjust based on your design */
  width: auto;  /* Maintains aspect ratio */
}

/* Media Query for Tablets and Mobile Devices */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stacks logo and nav vertically */
    align-items: flex-start; /* Aligns items to the start */
    padding: 15px 20px; /* Adjusts padding */
  }

  .nav-links {
    flex-direction: column; /* Stacks navigation links vertically */
    width: 100%; /* Makes nav take full width */
    gap: 10px; /* Reduces spacing between links */
  }

  .nav-links a {
    font-size: 1.2em; /* Increases font size for better touch targets */
  }
}

/* Ensure content doesn't overlap with the fixed footer */
#app {
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
